<template>
    <div class="topic-slot">
        <!-- <div></div> -->
        <router-link :to="{ name: 'web-app-topics', params: { topicSlug: topic.slug } }">
            <h2 class="topic-name">
                {{ topic.name }}
            </h2>
            <div class="total-memos">
                <strong>{{ topic.totalMemos || topic.total_memos }} </strong>
                <span>Memos</span>
            </div>
        </router-link>
        <follow-entity-button
            use-api-v2
            :entity-id="topic.id"
            :entity-name="topic.name"
            :is-following="Number(topic.is_following)"
            entity="topics"
            @toggled-follow="(is_following) => $emit('toggled-follow', is_following)"
        />
    </div>
</template>

<script>
export default {
    name: "TopicSlot",
    components: {
        FollowEntityButton: () => import(/* webpackChunkName: "follow-entity-button" */ "@c/atoms/follow-entity-button")
    },
    props: {
        topic: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.topic-slot {
    background-color: #1C1C1E;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    margin-bottom: 20px;

    a {
        color: inherit;
        text-decoration: none;
    }

    .topic-name {
        font-weight: normal;
        font-size: 18px;
        color: white;
    }

    .total-memos {
        display: flex;
        align-items: baseline;
        font-size: 14px;
        line-height: 14px;
        color: rgba(235, 235, 245, 0.6);
        margin-right: 10px;

        strong {
            color: white;
            font-weight: 600;
            display: block;
            margin-right: 5px;
        }
    }
}
</style>
